<template>
    <b-card class="mb-0">

        <b-row class="mb-1">
            <b-col class="d-flex justify-content-start align-items-center" cols="6" lg="6" md="6" sm="6" xs="6">
                <v-select :options="options" label="pagination" v-model="perPage" />
            </b-col>
            <b-col class="justify-content-end align-items-center" cols="6" md="6">
                <b-form-input style="margin-left: auto;" v-model="search" type="search" placeholder="Search" />
            </b-col>
        </b-row>


        <b-table id="generalLogTable" responsive show-empty :items="filterLog" :key="filterLog.id" :fields="fields"
            :per-page="perPage" :current-page="currentPage">
            <template #cell(id)="data">
                {{ data.item.id }}
            </template>

            <template #cell(type)="data">
                <b-badge :variant="`light-${categoryType(data.item.type)}`">
                    {{ resolveTypeText(data.item.type) }}
                </b-badge>
            </template>

            <template #cell(local)="data">
                {{ data.item.local }}
            </template>

            <template #cell(user)="data">
                <b-avatar-group v-if="data.item.user">
                    <b-avatar size="40" variant="info">
                        <b-image v-if="data.item.user.avatar" fluid :src="data.item.user.avatar"></b-image>
                        <div>
                            {{ data.item.user.name.substring(0, 1).toUpperCase() }}
                        </div>
                    </b-avatar>
                </b-avatar-group>
            </template>

            <template #cell(date)="data">
                {{ formatDate(data.item.date) }}
            </template>
        </b-table>

        <!-- Pagination -->
        <b-row align-h="end">
            <b-col md="12" class="d-flex align-items-center justify-content-end">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="generalLogTable"></b-pagination>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
import {
    BTable,
    BCard,
    BAvatar,
    BCol,
    BFormInput,
    BRow,
    BPagination,
    BAvatarGroup,
    BBadge,
} from "bootstrap-vue";
import { categoryType, resolveTypeText } from "@/utils/logType"
import vSelect from 'vue-select'
import moment from 'moment-timezone';


export default {
    components: {
        BTable,
        BCard,
        moment,
        BAvatar,
        vSelect,
        BFormInput,
        BCol,
        BRow,
        BPagination,
        BAvatarGroup,
        BBadge,
    },
    props: {
        logs: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            perPage: 50,
            currentPage: 1,
            search: '',
            options: [
                10, 50, 100
            ],
        }
    },

    computed: {
        changeLogs() {
            this.$store.state.changelogConfig.logs;
        },
        filterLog() {
            return this.logs.filter((log) => {
                return (
                    log.user.name.toLowerCase().includes(this.search.toLowerCase()) ||
                    log.date.toLowerCase().includes(this.search.toLowerCase()) ||
                    log.local.toLowerCase().includes(this.search.toLowerCase()) ||
                    log.type.toLowerCase().includes(this.search.toLowerCase())
                );
            })
        },

        rows() {
            return this.filterLog.length
        }
    },

    methods: {
        formatDate(date) {
            const localTime = moment.utc(date).tz('America/Sao_Paulo');
            return localTime.format('DD-MM-YYYY HH:mm');
        },
        categoryType(type) {
            return categoryType(type);
        },
        resolveTypeText(type) {
            return resolveTypeText(type);
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>