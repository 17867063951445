<template>
    <LogsTable :logs="logs" :fields="fields" />
</template>

<script>
import { logHeader } from "@/utils/headers.js";
import LogsTable from "@/components/atoms/logs/LogsTable.vue";

export default {
    components: {
        LogsTable
    },
    data() {
        return {
            fields: logHeader,
        }
    },
    async created() {
        await this.$store.dispatch("getChangelogs", {
            local: '',
            entity_id: ''
        });
    },
    computed: {
        logs() {
            return this.$store.state.changelogConfig.logs
        }
    },
}
</script>
