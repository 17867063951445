function categoryType(type) {
    if (type === "update") return "info";
    if (type === "create") return "success";
    if (type === "delete") return "danger";
    return "-";
  }

  function resolveTypeText(type) {
    if (type === "update") return "UPDATE";
    if (type === "create") return "CREATE";
    if (type === "delete") return "DELETE";
    return "-";
  }

  let typeOptions = ['create', 'update', 'delete']
  
  export { categoryType, resolveTypeText, typeOptions};